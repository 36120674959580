import { ConditionOperator, FlowActionFrequencyUnit, FlowActionFrequencyValue } from '@/modules/flowfx/api/flows.contracts';
import { ConditionName, FlowTrigger } from '@/modules/flowfx/types/flow.types';

export const en = {
  flowfx: {
    checked: 'Checked',
    unchecked: 'Unchecked',
    flow_actions: {
      archive: 'Archive',
      assign_routes: 'Assign Routes',
      assigned_routes_view: 'View Assigned Routes',
      copy: 'Copy',
      restore: 'Restore',
      view: 'View',
    },
    tabs: {
      archived: 'Archived',
      flows: 'Flows',
      routines: 'Routines',
    },
    routine: 'Routine',
    hide_actions: 'Hide actions',
    show_actions: 'Show actions',
    flow_archived: 'Flow successfully archived',
    flow_restored: 'Flow successfully restored',
    flow_copied: 'Flow successfully copied',
    active: 'Active',
    inactive: 'Inactive',
    add_actions: 'Add Actions',
    add_conditions: 'Add Conditions',
    add_routine: 'Add Routine',
    add_condition: 'Define Condition Details',
    add_group: 'Add And/Or Argument',
    are_you_sure: 'Are You Sure?',
    assigned_routes_remove: {
      title: 'Flow has routes assigned',
      message: 'Would you like to remove {amountOfAssignedRoutes} route(s) and set this flow as default?',
    },
    archive_activated_flow: {
      title: 'Flow is active',
      message: 'Would you like to archive this active flow?',
    },
    remove_actions: 'This will remove all actions from this part of the flow.',
    remove_conditions: 'This will remove this part of the flow and any attached actions/conditions.',
    is_active: {
      activated: 'Flow activated',
      deactivated: 'Flow deactivated',
    },
    all_systems_go: {
      only_run_on: 'Only Run On',
      only_run_on_required: 'Please select at least one day.',
    },
    schedule: {
      add_time: 'Add a Time',
      remove_time: 'Remove Time',
      time_difference_violation: 'The selected times must be at least 2 hours apart.',
      timezone_description: 'The scheduled times are based on the following time zone: {timezone}.',
    },
    checks: {
      remove_group: 'Remove Group',
      add_condition_or_group: 'Add Condition or Group',
      remove_condition: 'Remove Condition',
      add_branch: 'Add Branch',
      remove_branch: 'Remove Branch',
    },
    default_changed: {
      activated: 'Flow is marked as default',
      deactivated: 'Flow is removed as default',
    },
    else_branch_disabled: `The 'Else' option is not available for this trigger type.`,
    conditions: 'Conditions: And...',
    default_event_flow_name: 'New Flow',
    default_routine_flow_name: 'New Routine',
    do: 'Actions: Do...',
    errors: {
      default_flow_exists: 'A default flow for this trigger type already exists.',
      no_routes_assigned_to_flow: 'The flow has no routes assigned and is not marked as the default flow.',
      reached_max_number_of_routines: 'You reached the maximum number of routines. Please delete a routine first.',
      event_based_flow_cannot_be_converted_to_routine_one: "You can't convert an event-based automation into a routine one",
      routine_flow_cannot_be_converted_to_event_based_one: "You can't convert a routine automation into an event-based one",
      trigger_type_with_schedule_cannot_be_changed:
        "The 'All Systems Go' and 'Schedule' flow types can only be switched between each other",
    },
    no_actions_available: 'No quick actions available',
    no_queries_available: 'No saved queries available',
    select_query: 'Select a saved query',
    select_action: 'Select a quick action',
    flow_created: 'Flow created',
    flow_updated: 'Flow updated',
    flow_is_reset: 'Changing the trigger type will reset your flow. All actions and conditions will be removed.',
    flowfx: 'FlowFx',
    flows_help_automate: 'Flows help you to automate day to day tasks.',
    get_flow_failed: 'Failed to retrieve flow',
    flow_does_not_exist: 'Flow does not exist',
    loading_flow: 'Loading flow...',
    loading_flows: 'Loading flows...',
    loading_routines: 'Loading routines...',
    loading_routes: 'Loading routes...',
    new_flow: 'Create New Flow',
    new_routine: 'Create New Routine',
    no_flows: "You don't have any flows yet",
    no_archived_flows: "You don't have any archived flows",
    no_routine_flows: "You don't have any Routine flows",
    actions: 'Actions',
    routes: 'Routes',
    timezone: 'Timezone',
    copying: 'You are copying:',
    placeholders: {
      search_routes: 'Search routes',
      delta_value: 'Δ',
      delta_days: 'Days',
    },
    route_flows: 'Flows Overview',
    routine_flows: 'Routines Overview',
    archived_flows: 'Archived Overview',
    triggers: {
      [FlowTrigger.NEW_FLIGHT]: {
        title: 'New Flight is Detected',
        info: 'Activated when a new flight enters the system',
      },
      [FlowTrigger.FLIGHT_CABIN_CAPACITY_CHANGE]: {
        title: 'Change in Flight Cabin Capacity',
        info: 'Activated when the capacity of the flight cabin changes',
      },
      [FlowTrigger.BOOKINGS_CHANGE]: {
        title: 'Change in Bookings',
        info: 'Activated when the number of bookings on a flight changes, either through a new or a cancelled booking',
      },
      [FlowTrigger.ALL_SYSTEMS_GO]: {
        title: 'All Systems Go',
        info: 'Activated when Eddy has finished its daily batch process and processed any applicable optimization profile processes and workflows. This trigger can only run once a day',
      },
      [FlowTrigger.FLIGHT_DEPARTURE_TIME_CHANGE]: {
        title: 'Change in Flight Departure Time',
        info: "Activated when a flight's departure time changes",
      },
      [FlowTrigger.SCHEDULED]: {
        title: 'Schedule',
        info: 'Activated when the chosen date/time in the trigger is reached',
      },
      [FlowTrigger.ROUTINE]: {
        title: 'Routine',
        info: 'The routine will run on each of the scheduled date/time combinations',
      },
      [FlowTrigger.BYOD_CHANGE]: {
        // Volaris uses BYOD for looks and books and thus BYOD is translated to looks & books as it's not used for actual BYOD fields atm.
        title: 'Change in Looks and Books',
        info: 'Activated if there is a change in the Looks, Books, and/or Look to Books Ratio values. This only applies if the values are different than the existing information in Eddy, or if new flight keys are added',
      },
      [FlowTrigger.COMPETITIVE_FARE_CHANGE]: {
        title: 'Change in Competitor Pricing',
        info: 'Activated when a competitive fare changes',
      },
    },
    unknown_trigger: 'Unknown Trigger',
    overview: {
      headers: {
        activated: 'Activated',
        name: 'Name',
        trigger_type: 'Trigger Type',
        last_modified: 'Last Modified',
        last_modified_by: 'Last Modified By',
        owner: 'Owner',
        actions: 'Actions',
        default: 'Default',
        assigned_routes: 'Assigned Routes',
        last_triggered: 'Last Triggered',
        last_24_hours: 'Last 24 Hours',
        last_7_days: 'Last 7 Days',
      },
      header_tooltips: {
        actions: 'Choose an action to apply on the flow.',
        activated: 'Set the flow to active or inactive.',
        assigned_routes: 'Routes potentially affected by the flow.',
        default: 'Set the flow to be the default flow. A default flow will apply to every route.',
        last_24_hours: 'The amount of actions the flow has executed in the last 24 hours.',
        last_7_days: 'The amount of actions the flow has executed in the last 7 days.',
        last_modified: 'When was the flow last modified.',
        last_modified_by: 'Who made the last change to the flow.',
        last_triggered: 'The date and time the flow was last triggered.',
        name: 'The name of the flow.',
        owner: 'The original creator of the flow.',
        trigger_type: 'What causes the flow to be triggered.',
      },
    },
    not_triggered: 'Not triggered',
    default: 'Default',
    not_default: 'Not default',
    default_disabled:
      'This flow can not be set as default. You either do not have the permission or Eddy does not allow you to set this flow as default for this trigger type.',
    routes_selected: 'No routes selected | {n} route selected | {n} routes selected',
    routes_table: {
      flight_key: 'Route Key',
      carrier_code: 'Carrier Code',
      origin: 'Origin',
      destination: 'Destination',
      origin_country_code: 'Origin Country Code',
      destination_country_code: 'Destination Country Code',
      assigned_flow: 'Assigned Flow',
    },
    routes_updated: 'Routes for flow updated',
    set_as_default: {
      title: 'Set your flow as the default? Or assign routes?',
      message:
        'Clicking “Set as default” will configure the flow with name "{flowName}" the default flow for trigger type "{triggerType}". There can only be one default flow configured per trigger type. Clicking "Assign routes" will assign only the selected routes to this flow.',
      action: 'Set as default',
      skip_for_now: 'Skip for now',
    },
    selected_routes_already_assigned: {
      title: 'Route(s) assigned to a flow selected',
      description:
        'The route(s) with route key(s): {flightKeys}, are already assigned to a flow with the same trigger type. When confirmed these routes will be reassigned to this flow.',
    },
    overwrite_default: {
      title: 'Default flow already assigned',
      message: 'This will replace flow "{currentDefaultFlowName}" with trigger "{triggerType}" as the default. Continue?',
    },
    select: 'Select',
    revert_changes: 'Revert Changes',
    revert_to_original_flow: 'This will revert all changes made to the flow.',
    reverted_to_original_flow: 'Changes to flow reverted',
    action_frequency: 'Action Frequency',
    action_frequency_description: {
      title: 'What is Action Frequency?',
      description: 'Define how often you want to execute the defined actions on the eligible flights for the duration configured.',
      frequencies: [
        {
          label: 'Always:',
          description:
            'Every time the event-based trigger is detected, and the conditions defined for the branch are met, then the branch actions on the eligible flights will execute.',
        },
        {
          label: `Every 'x' hour(s):`,
          description:
            'Branch actions will not run for x amount of hours. For example; the actions will not run for six hours after they were last executed.',
        },
        {
          label: "Once in the Flight's lifetime:",
          description: 'Branch actions for the eligible flights will only be executed once.',
        },
      ],
    },
    frequencies: {
      [FlowActionFrequencyValue.ALWAYS]: 'Always',
      [FlowActionFrequencyValue.FLIGHT_LIFETIME]: "Once in the Flight's Lifetime",
      [FlowActionFrequencyValue.EVERY]: 'Every',
    },
    interval_units: {
      [FlowActionFrequencyUnit.HOURS]: 'hours | hour | hours',
      [FlowActionFrequencyUnit.DAYS]: 'days | day | days',
    },
    trigger: 'Trigger: When...',
    conditionFieldNames: {
      [ConditionName.AUTOPILOT]: 'Autopilot',
      [ConditionName.AVERAGE_FARE]: 'Average Fare',
      [ConditionName.BOOKINGS]: 'Bookings',
      [ConditionName.CAPACITY]: 'Capacity',
      [ConditionName.CAPACITY_LOAD_FACTOR]: 'Capacity Load Factor',
      [ConditionName.CARRIER_CODE]: 'Carrier Code',
      [ConditionName.CLUSTER]: 'Cluster',
      [ConditionName.DAY_OF_WEEK]: 'Departure Day of the Week',
      [ConditionName.DAYS_SINCE_LAST_BOOKING]: 'Days Since Last Booking',
      [ConditionName.DAYS_SINCE_LAST_MODIFIED]: 'Days Since Last User Action',
      [ConditionName.DAYS_SINCE_LAST_VIEWED]: 'Days Since Last User Review',
      [ConditionName.DEPARTURE_DATE]: 'Departure Date',
      [ConditionName.DEPARTURE_TIME]: 'Departure Time',
      [ConditionName.EQUIPMENT_TYPE]: 'Equipment Type',
      [ConditionName.FLIGHT_NUMBER]: 'Flight Number',
      [ConditionName.LAF]: 'LAF',
      [ConditionName.LID]: 'Lid',
      [ConditionName.LID_LOAD_FACTOR]: 'Lid Load Factor',
      [ConditionName.MIN_COMPETITIVE_FARE_DELTA]: '∆ CF',
      [ConditionName.MIN_RIVAL_FARE_DELTA]: '∆ RF',
      [ConditionName.MIN_COMPETITIVE_FARE]: 'Min CF',
      [ConditionName.MIN_RIVAL_FARE]: 'Min RF',
      [ConditionName.OWN_FARE]: 'Own Fare',
      [ConditionName.NUMBER_OF_DAYS_OUT]: 'Number of Days Out',
      [ConditionName.OPTIMISATION_PROFILE_GROUP_LEVEL]: 'Optimisation Profile Group/Level',
      [ConditionName.OPTIMISATION_PROFILE_LEVEL]: 'Optimisation Profile Level',
      [ConditionName.OPTIMISATION_TACTIC]: 'Optimisation Tactic',
      [ConditionName.OVERNIGHTS]: 'Number of Overnights',
      [ConditionName.PERFORMANCE_BAND]: 'Performance Band',
      [ConditionName.PRICING_ADJUSTMENT]: 'Tactical Adjustment',
      [ConditionName.PRICING_AGGREGATION]: 'Price Increment',
      [ConditionName.PRICING_TACTIC]: 'Pricing Tactic',
      [ConditionName.REVENUE]: 'Revenue',
      [ConditionName.TAGS]: 'Tags',
      [ConditionName.LOOKS]: 'Looks',
      [ConditionName.BOOKS]: 'Books',
      [ConditionName.LOOKS_BOOKS_RATIO]: 'L2B Ratio',
      [ConditionName.PERFORMANCE_BAND_PICKUP]: 'Performance Band Pickup',
      cabin: 'Cabin',
      class: 'Class',
      flight: 'Aircraft',
      foo: 'Foo', // For the tests
    },
    conditionOperators: {
      [ConditionOperator.EQUAL]: 'Equals',
      [ConditionOperator.NOT_EQUAL]: 'Does Not Equal',
      [ConditionOperator.GREATER_THAN]: 'Greater Than',
      [ConditionOperator.GREATER_THAN_OR_EQUAL]: 'Greater Than Or Equal To',
      [ConditionOperator.SMALLER_THAN]: 'Less Than',
      [ConditionOperator.SMALLER_THAN_OR_EQUAL]: 'Less Than Or Equal To',
      [ConditionOperator.BETWEEN]: 'Between',
      [ConditionOperator.NOT_BETWEEN]: 'Not Between',
      [ConditionOperator.IN]: 'In',
      [ConditionOperator.NOT_IN]: 'Not In',
      [ConditionOperator.INCREASED]: 'Increased By',
      [ConditionOperator.DECREASED]: 'Decreased By',
      [ConditionOperator.ANY]: 'At Least One Of',
      [ConditionOperator.ALL]: 'All Of',
      [ConditionOperator.CONTAINS]: 'Contains',
      [ConditionOperator.NOT_CONTAINS]: 'Not Contains',
      [ConditionOperator.NONE]: 'None Of',
    },
    conditionOperatorsOverrides: {
      lower_than: 'Lower Than',
      lower_than_or_equal: 'Lower Than Or Equal',
      higher_than: 'Higher Than',
      higher_than_or_equal: 'Higher Than Or Equal',
    },
    conditionOperatorsTooltipOverrides: {
      delta_cf_higher_lower_than:
        'Difference to the competitive fare price. A positive Δ CF means the competitive fare is more than your own fare. A negative Δ CF means the competitive fare is less than your own fare. For example: Δ CF = -15 USD; your competition is 15 USD cheaper.',
      delta_rf_higher_lower_than:
        'Difference to the minimum rival fare. A positive Δ RF means the rival fare is more than your own fare. A negative Δ RF means the rival fare is less than your own fare. For example: Δ RF = 35 USD; your rival fare is 35 USD more expensive. If your condition is Δ RF higher than -15 USD, this would return TRUE for a Δ RF value of -10 USD.',
    },
    labels: {
      name: 'Name:',
      filter_by_saved_query: 'Filter flights by saved query:',
      time_of_day: 'Time(s) of day:',
      run_routine_on: 'Run this Routine on:',
      run_following_quick_actions: 'Run the following quick actions:',
      selected: 'Selected',
      hours: 'Hours:',
      minutes: 'Minutes:',
      percentage_symbol: '%',
      search_route: 'Search route:',
      match_all: 'Match all',
      input_booking: {
        today: "Today's capture",
        yesterday: "Yesterday's capture",
      },
      any: 'Any Carrier',
      carrier_code: 'No Carriers found | Carrier | Carriers',
      days: 'Day | Days',
      last: 'last',
      and: 'and',
    },
    tooltips: {
      no_checks: 'No checks have been added to this condition yet.',
    },
    select_all: 'Select all',
    deselect_all: 'Deselect all',
    x_out_of_xx_selected: '{x}/{xx} selected',
    confirm_delete_routine: 'Are you sure you want to delete this routine?',
    no_match: {
      operator: 'No matching operator',
      condition: 'No matching condition',
      unit: 'No matching unit',
      carrier_code: 'No result',
      classes: 'No classes found',
    },
    required_field: {
      trigger_schedule_times: 'Select at least a single time slot',
      condition_field: 'Select all fields',
    },
    invalid_field: {
      trigger: `Trigger '{trigger}' is not valid`,
      trigger_schedule_days: 'Select at least a single day',
      trigger_schedule_times_interval: 'Times need to be at least 2 hours apart (eg. 13:00, 15:00)',
      condition_field_unknown: 'Selected field is invalid',
      condition_operator: 'Selected operator is invalid',
      flow_name: 'Only letters, numbers, spaces, underscores and dashes are allowed',
      no_negative_values: 'Enter a positive number',
      range: 'Enter a valid range',
    },
  },
};
