import { FilterField, FilterFieldField, FilterGroup } from '@/modules/api/shared-contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { ControlModule } from '@/store/modules/control.module';
import { getFlightNumberRangeQueryString } from '@/utils';

export class QueryTransformService {
  public static transformToParams(filters: FilterFieldDefinition[] = []): { [key: string]: string } {
    const urlParams: { [key: string]: string } = {};

    filters.forEach((filterField: FilterFieldDefinition) => {
      if (!filterField.value || (filterField.value as any).length === 0) {
        // TODO: Improve by splitting filters and groups
        const filterGroup = filterField as unknown as FilterGroup;

        if (
          filterGroup.fields &&
          filterGroup.fields.some((field) => (field as unknown as FilterField).field === FilterFieldField.flightNumber)
        ) {
          urlParams[FilterFieldField.flightNumber] = getFlightNumberRangeQueryString(filterGroup.fields);
        }
        return;
      }

      const fieldDefinition: FilterFieldDefinition | undefined = filterField.transformToParams
        ? filterField
        : ControlModule.filters.find((ffd) => {
            // Unfortunately there are a few exceptions left where we need to plot the filterField.field on a filter with a slightly different name
            if (filterField.field === FilterFieldField.departureDate) {
              return ffd.field === FilterFieldField.departureDateRange;
            }
            if (filterField.field === FilterFieldField.flightNumber) {
              return ffd.field === FilterFieldField.flightNumberRange || ffd.field === FilterFieldField.flightNumber;
            }
            return ffd.field === filterField.field;
          });

      if (!fieldDefinition) {
        console.warn(filterField.field + ' is not correctly defined');
        return;
      }
      urlParams[fieldDefinition.field] = fieldDefinition.transformToParams
        ? fieldDefinition.transformToParams(filterField.value, filterField.type, urlParams)
        : fieldDefinition.value;
    });
    return urlParams;
  }
}
