export const en = {
  booking_details: {
    titles: {
      booking_details: 'Booking Details',
    },
    control: {
      booking_details: 'Booking Details',
    },
    labels: {
      with_bookings: 'With Bookings',
      without_bookings: 'Without Bookings',
    },
    table_columns: {
      flight_sequence: 'Flight Sequence',
      flight_sequence_short: 'Flight Seq',
      flight_sequence_description:
        'The nth flight of your journey. \n' +
        'For example, your itinerary is A-B-C. If your flight is from B to C, this field will display 2 as it is the second flight of your journey.',
      seat_assignment: 'Seat Assignment',
      seat_assignment_short: 'Seat',
      seat_assignment_description: "The passenger's seat row and number.",
      lift_status: 'Lift Status',
      lift_status_short: 'Lift',
      lift_status_description: 'Status for departed flights. \n' + '0: Default\n' + '1: Checked in\n' + '2: Boarded\n' + '3: No show',
      cabin_code: 'Cabin Code',
      cabin_code_short: 'Cabin',
      cabin_code_description: 'The booking cabin.\n' + 'For example: Y, C, or J.',
      nest_code: 'Nest Code',
      nest_code_short: 'Nest',
      nest_code_description:
        'The booking nest code. A nest is a logical grouping of classes within a cabin used to control/influence availability. The nest code is the identifier for each specific nest within the cabin. Each cabin has one or more nest.\n' +
        'For example: 0, 1.',
      boarding_sequence: 'Boarding Sequence',
      boarding_sequence_short: 'Brd Seq',
      boarding_sequence_description:
        'The sequence number in which you have checked in. \n' +
        'For example: if it shows 14, this means you are the 14th person to check in.',
      booking_code: 'Booking Code',
      booking_code_short: 'Bk Code',
      booking_code_description: 'The six-character booking code or PNR (passenger name record).',
      ticket_code: 'Ticket Code',
      ticket_code_short: 'Tkt Code',
      ticket_code_description: 'The ticket number associated with the PNR. ',
      fare_basis_code: 'Fare Basis Code',
      fare_basis_code_short: 'FBC',
      fare_basis_code_description: 'A code used to identify the fare type.',
      class_code: 'Class',
      class_code_description: 'The class of the booking.',
      type_code: 'Trip Code',
      type_code_short: 'Trip',
      type_code_description: '1: One way\n' + '2: Round trip\n' + '3: Open jaw',
      booking_status: 'Booking Status',
      booking_status_short: 'Bk Sts',
      booking_status_description: 'The booking status code.\n' + 'For example: HK, HL, UX.',
      currency: 'Currency',
      currency_short: 'Curr',
      currency_description: 'The booking currency code.',
      base_fare: 'Original Base Fare',
      base_fare_short: 'Orig Base Fare',
      base_fare_description: 'The base fare in the booked currency.',
      net_fare: 'Original Fare Revenue',
      net_fare_short: 'Orig Fare Rev',
      net_fare_description: 'The net fare in the booked currency.',
      tax1: 'Original Tax 1',
      tax1_short: 'Orig Tax 1',
      tax1_description: 'The tax paid in the booked currency.',
      tax2: 'Original Tax 2',
      tax2_short: 'Orig Tax 2',
      tax2_description: 'The tax paid in the booked currency.',
      tax3: 'Original Tax 3',
      tax3_short: 'Orig Tax 3',
      tax3_description: 'The tax paid in the booked currency.',
      tax4: 'Original Tax 4',
      tax4_short: 'Orig Tax 4',
      tax4_description: 'The tax paid in the booked currency.',
      tax5: 'Original Tax 5',
      tax5_short: 'Orig Tax 5',
      tax5_description: 'The tax paid in the booked currency.',
      tax6: 'Original Tax 6',
      tax6_short: 'Orig Tax 6',
      tax6_description: 'The tax paid in the booked currency.',
      total_cost: 'Original Total Fare Revenue',
      total_cost_short: 'Orig Total Fare Rev',
      total_cost_description: 'The total fare paid in the booked currency.',
      booking_agent: 'Booking Agent',
      booking_agent_short: 'Bk Agt',
      booking_agent_description: 'The unique identifier of the booking agent.',
      selling_agent: 'Selling Agent',
      selling_agent_short: 'Sell Agt',
      selling_agent_description: 'The unique identifier of the selling agent.',
      booking_date: 'Booking Date',
      booking_date_short: 'Bk Date',
      booking_date_description: 'The date the booking was made.',
      booking_time: 'Booking Time',
      booking_time_short: 'Bk Time',
      booking_time_description: 'The time the booking was made (UTC time).',
      neutral_unit_of_construct: 'Neutral Unit of Construct',
      neutral_unit_of_construct_short: 'NUC',
      neutral_unit_of_construct_description: 'The currency exchange rate used to convert an amount to the neutral unit of construction.',
      passenger_type: 'Passenger Type',
      passenger_type_short: 'PAX Type',
      passenger_type_description:
        'Passenger types can indicate age, companion status, organizational affiliation, private fare eligible, and need for special assistance, among others. \n' +
        'For example: ADT (adult), CHD (child), INF (infant).',
      promotion_flag: 'Promotion',
      promotion_flag_short: 'Promo',
      promotion_flag_description: 'Was the flight part of a promotion.',
      discount: 'Discount',
      discount_short: 'DC',
      discount_description: 'The discount provided to the booking.',
      dynamic_price_adjustment: 'Dynamic Price Adjustment',
      dynamic_price_adjustment_short: 'Dyn Price Adj',
      dynamic_price_adjustment_description: 'The dynamic pricing adjustment to the booking.',
      price_adjustment_applied: 'Price Adjustment Applied',
      price_adjustment_applied_short: 'Price Adj',
      price_adjustment_applied_description: 'Was a price adjustment applied to the booking.',
      journey: 'Journey',
      journey_description: 'The passenger itinerary.',
      days_booking_in_advance: 'Days Booked in Advance',
      days_booking_in_advance_short: 'Bk NDO',
      days_booking_in_advance_description: 'The number of days before the departure date that the booking was made.',
      hour_of_booking: 'Booking Hour',
      hour_of_booking_short: 'Bk Hour',
      hour_of_booking_description: 'The hour the booking was made.\n' + 'For example: 0 or 23.',
    },
  },
};
