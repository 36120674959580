import { isEmpty, isFinite, isString } from 'lodash-es';

import { ApiClient } from '@/modules/api/base-client';

import { Authorities, IAuthService, LoginRequestModel, LoginResponseModel } from './auth-contracts';

class AuthService implements IAuthService {
  public login(credentials: LoginRequestModel): Promise<LoginResponseModel> {
    return ApiClient.getToken<LoginResponseModel>(`/token`, credentials);
  }

  // The username cannot be equal to or part of the password
  // at least 8 characters in length
  // an upper case character [A-Z]
  // a lower case character [a-z]
  // a digit [0-9]
  // a non-character/digit symbol [for example: !@#$]
  public validatePassword(
    username: string,
    password: string,
  ): {
    containsNoUsername: boolean;
    containsEnoughCharacters: boolean;
    containsUppercase: boolean;
    containsLowercase: boolean;
    containsDigit: boolean;
    containsSymbol: boolean;
  } {
    const result = {
      containsNoUsername: false,
      containsEnoughCharacters: false,
      containsUppercase: false,
      containsLowercase: false,
      containsDigit: false,
      containsSymbol: false,
    };

    if (!isString(password) || isEmpty(password)) {
      return result;
    }

    result.containsNoUsername = !username.includes(password);
    result.containsEnoughCharacters = password.length >= 8;

    const specialCharacterSet = new Set('<>@!#$%^&*()_+[]{}?:;|\'"\\,./~`-=');

    for (const character of password) {
      if (isFinite(+character)) {
        result.containsDigit = true;
      } else if (specialCharacterSet.has(character)) {
        result.containsSymbol = true;
      } else if (character == character.toLowerCase()) {
        result.containsLowercase = true;
      } else if (character == character.toUpperCase()) {
        result.containsUppercase = true;
      }
    }

    return result;
  }

  public hasAuthority(authoritiesToCheck: Authorities[], authorities: Authorities[]): boolean {
    return authoritiesToCheck.some((authority: Authorities) => authorities.includes(authority));
  }
}

export const authService = new AuthService();
