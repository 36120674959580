import { Library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faArrowAltToBottom,
  faArrowDown,
  faArrowLeft,
  faArrowsH,
  faBars,
  faBolt,
  faCalendar,
  faCalendarStar,
  faCalendarTimes,
  faCheck,
  faClock,
  faCloudUpload,
  faCog,
  faCopy,
  faDownload,
  faEnvelope,
  faExchange,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faHockeySticks,
  faIndustry,
  faInfoCircle,
  faLink,
  faList,
  faLock,
  faLockOpen,
  faLongArrowRight,
  faMobile,
  faMoneyBillWave,
  faPaperPlane,
  faPen,
  faPercentage,
  faPlayCircle,
  faPlus,
  faPlusHexagon,
  faRobot,
  faSave,
  faSearch,
  faServer,
  faSms,
  faSpinnerThird,
  faTasks,
  faText,
  faTimes,
  faTrash,
  faTrashAlt,
  faUndo,
  faUser,
  faWaveSine,
} from '@fortawesome/pro-light-svg-icons';

export function addLightIcons(library: Library): void {
  library.add(
    faArchive,
    faArrowAltToBottom,
    faArrowLeft,
    faArrowDown,
    faArrowsH,
    faBars,
    faBolt,
    faCalendar,
    faCalendarStar,
    faCalendarTimes,
    faCheck,
    faClock,
    faCloudUpload,
    faCog,
    faCopy,
    faDownload,
    faEnvelope,
    faExchange,
    faExclamationTriangle,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFile,
    faHockeySticks,
    faIndustry,
    faInfoCircle,
    faLink,
    faList,
    faLock,
    faLockOpen,
    faLongArrowRight,
    faMobile,
    faMoneyBillWave,
    faPaperPlane,
    faPen,
    faPercentage,
    faPlayCircle,
    faPlus,
    faPlusHexagon,
    faRobot,
    faSave,
    faSearch,
    faServer,
    faSms,
    faSpinnerThird,
    faTasks,
    faText,
    faTimes,
    faTrash,
    faTrashAlt,
    faUndo,
    faUser,
    faWaveSine,
  );
}
