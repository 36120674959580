export interface LoginRequestModel {
  password: string;
  grant_type: string;
}

export interface LoginResponseModel {
  access_token: string;
  username: string;
}

export interface AuthTokenModel {
  exp?: number;
  sub: string;
  username: string;
  user_name: string;
  name: string;
  iat: number;
  jti: string;
  firebaseUserId: string;
  scope: string[];
  authorities: Authorities[];
}

export enum Authorities {
  ApplicationsettingRead = 'application-setting:read',
  AuditRead = 'audit:read',
  BatchjobCreate = 'batch-job:create',
  ClustersRead = 'clusters:read',
  ClustersUpdate = 'clusters:update',
  CurrencyRead = 'currency:read',
  DataImportUpdate = 'data-import:update',
  FareRead = 'fare:read',
  FlightnumberRead = 'flight-number:read',
  FlightRead = 'flight:read',
  FlightTagDelete = 'flight-tag:delete',
  FlightTagUpdate = 'flight-tag:update',
  FlightUpdate = 'flight:update',
  FlightUpdateAssigned = 'flight:update_assigned',
  FlowFxCreate = 'automations:create',
  FlowFxRead = 'automations:read',
  FlowFxUpdate = 'automations:update',
  FlowFxUpdateOwn = 'automations:update-own',
  HistoryCurrencyRead = 'history-currency:read',
  LinkedClassRulesRead = 'linked-class-rules:read',
  LinkedClassRulesUpdate = 'linked-class-rules:update',
  RivalRulesRead = 'rival-rules:read',
  RivalRulesUpdate = 'rival-rules:update',
  OptimisationProfileCreate = 'optimisation-profile:create',
  OptimisationProfileRead = 'optimisation-profile:read',
  OptimisationProfileUpdate = 'optimisation-profile:update',
  PacingCurvesRead = 'pacing-curves:read',
  PacingCurvesUpdate = 'pacing-curves:update',
  PromotionRead = 'promotions:read',
  PromotionUpdate = 'promotions:update',
  QueryCreate = 'query:create',
  QueryDelete = 'query:delete',
  QueryRead = 'query:read',
  QueryUpdate = 'query:update',
  ReportUpdate = 'report:update',
  RoleRead = 'role:read',
  RouteContinuousPricingRead = 'route-continuous-pricing:read',
  RouteContinuousPricingUpdate = 'route-continuous-pricing:update',
  RouteRead = 'route:read',
  RoutesUserDelete = 'routes_user:delete',
  RoutesUserUpdate = 'routes_user:update',
  RouteUpdate = 'route:update',
  SystemSettingRead = 'system-setting:read',
  SystemSettingUpdate = 'system-setting:update',
  CustomerConfigRead = 'customer-config:read',
  CustomerConfigUpdate = 'customer-config:update',
  SystemUpdate = 'system:update',
  TagCreate = 'tag:create',
  TagRead = 'tag:read',
  UserCreate = 'user:create',
  UserRead = 'user:read',
  UsersettingRead = 'user-setting:read',
  UsersettingUpdate = 'user-setting:update',
  UserUpdate = 'user:update',
  RevenueComponentRead = 'revenueComponent:read',
  RevenueComponentUpdate = 'revenueComponent:update',
  InsightsView = 'insights:view',
}

export interface IAuthService {
  login(credentials: LoginRequestModel): Promise<LoginResponseModel>;
  hasAuthority(authoritiesToCheck: Authorities[], authorities: Authorities[]): boolean;
}
