import { ColGroupDef, ColumnState } from 'ag-grid-enterprise';

import {
  generateInventoryActiveOptimisationTacticsColumnsState,
  generateInventoryShadowOptimisationTacticsColumnsState,
  generateInventorySharedCabinClassSourceFlightColumnsState,
  generateInventorySharedFlightClassColumnsState,
  getInventoryActiveOptimisationTacticsColumns,
  getInventoryShadowOptimisationTacticsColumns,
  getInventorySharedCabinClassSourceFlightColumns,
  getInventorySharedFlightClassColumns,
} from '@/models/columns/collections/inventory/shared';
import * as CabinClassColumns from '@/models/columns/definitions';
import { ColumnGroupId, ColumnId } from '@/models/enums/grid';
import { i18n } from '@/plugins/i18n';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const { t } = i18n.global;

export const InventoryGridColumnDefinitions1a = (bookingsPickupDays: number[]): ColGroupDef[] => {
  const inventoryFlightClassColumns = [
    ...getInventorySharedFlightClassColumns(bookingsPickupDays),
    CabinClassColumns.SegmentAvailabilityColumn,
    CabinClassColumns.generateUpdatedSegmentLimitColumn(true),
    CabinClassColumns.UpdatedMinControlColumn,
    CabinClassColumns.UpdatedEffectiveDateColumn,
    CabinClassColumns.UpdatedDiscontinueDateColumn,
    CabinClassColumns.EffectiveDateColumn,
    CabinClassColumns.DiscontinuedDateColumn,
    CabinClassColumns.WaitListMaxColumn,
    CabinClassColumns.WaitListMaxPercentageColumn,
    CabinClassColumns.GroupPendingColumn,
    CabinClassColumns.GroupWaitlistedColumn,
    CabinClassColumns.UpdatedWaitlistMaxColumn,
    CabinClassColumns.UpdatedWaitlistMaxPercentageColumn,
  ];
  if (CustomerSettingsModule.settings.hasNegoAllocationEnabled) {
    inventoryFlightClassColumns.push(CabinClassColumns.NegotiatedAllottedSeatsColumn);
  }

  const inventoryColumns = [
    {
      headerName: `${t('flight')} | ${t('class')}`,
      groupId: ColumnGroupId.InventoryFlightLineGroup,
      children: inventoryFlightClassColumns,
    },
    {
      headerName: `${t('flight')} | ${t('source_flight')}`,
      groupId: ColumnGroupId.ClassSourceFlightGroup,
      children: [
        ...getInventorySharedCabinClassSourceFlightColumns(),
        CabinClassColumns.CabinClassSourceGroupBoardingsColumn,
        CabinClassColumns.CabinClassSourceGroupBookingsColumn,
        CabinClassColumns.CabinClassSourceFinalGroupBookingsColumn,
      ],
    },
    {
      headerName: `${t('active_optimisation_tactics')}`,
      groupId: ColumnGroupId.ActiveOptimisationTacticsGroup,
      children: [...getInventoryActiveOptimisationTacticsColumns()],
    },
  ];

  if (CustomerSettingsModule.settings.hasShadowTacticsEnabled) {
    inventoryColumns.push({
      headerName: t('shadow_tactics'),
      groupId: ColumnGroupId.ShadowOptimisationTacticsGroup,
      children: [...getInventoryShadowOptimisationTacticsColumns()],
    });
  }

  return inventoryColumns;
};

export const generateInventoryGridDefaultColumnState1a = (bookingsPickupDays: number[]): ColumnState[] => {
  const columns = [
    ...generateInventorySharedFlightClassColumnsState(bookingsPickupDays),
    { colId: ColumnId.SegmentAvailability, hide: true, sort: null },
    { colId: ColumnId.UpdatedSegmentLimit, hide: true, sort: null },
    { colId: ColumnId.UpdatedMinControl, hide: true, sort: null },
    { colId: ColumnId.UpdatedEffectiveDate, hide: true, sort: null },
    { colId: ColumnId.EffectiveDate, hide: true, sort: null },
    { colId: ColumnId.UpdatedDiscontinueDate, hide: true, sort: null },
    { colId: ColumnId.DiscontinueDate, hide: true, sort: null },
    { colId: ColumnId.UpdatedWaitlistMax, hide: true, sort: null },
    { colId: ColumnId.WaitListMax, hide: true, sort: null },
    { colId: ColumnId.UpdatedWaitlistMaxPercentage, hide: true, sort: null },
    { colId: ColumnId.WaitListMaxPercentage, hide: true, sort: null },
    { colId: ColumnId.GroupPending, hide: true, sort: null },
    { colId: ColumnId.GroupWaitlisted, hide: true, sort: null },
    ...generateInventorySharedCabinClassSourceFlightColumnsState(),
    { colId: ColumnId.SourceGroupBoardings, hide: true, sort: null },
    { colId: ColumnId.SourceGroupBookings, hide: true, sort: null },
    { colId: ColumnId.SourceFinalGroupBookings, hide: true, sort: null },
    ...generateInventoryActiveOptimisationTacticsColumnsState(),
  ];

  if (CustomerSettingsModule.settings.hasShadowTacticsEnabled) {
    columns.push(...generateInventoryShadowOptimisationTacticsColumnsState());
  }

  if (CustomerSettingsModule.settings.hasNegoAllocationEnabled) {
    columns.push({ colId: ColumnId.NegotiatedAllottedSeats, hide: true, sort: null });
  }
  return columns;
};
