import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const hasAutomationsEnabled: () => boolean = (): boolean => CustomerSettingsModule.settings.hasAutomationsEnabled;

export const FLOW_FX_ROUTES: RouteRecordRaw[] = [
  {
    path: 'flowfx',
    name: RouteName.flowFx,
    redirect: { name: RouteName.flowsOverview },
    meta: {
      authorities: [Authorities.FlowFxRead],
      isAllowed: hasAutomationsEnabled,
    },
    children: [
      {
        path: 'flows',
        name: RouteName.flowsOverview,
        component: () => import('../views/FlowsOverview.vue'),
      },
      {
        path: 'flows/create',
        name: RouteName.flowCreate,
        meta: {
          authorities: [Authorities.FlowFxCreate],
          isAllowed: hasAutomationsEnabled,
        },
        component: () => import('../views/FlowCreate.vue'),
      },
      {
        path: 'flows/:id',
        name: RouteName.flowDetails,
        component: () => import('../views/FlowDetails.vue'),
      },
      {
        path: 'routines',
        name: RouteName.routinesOverview,
        component: () => import('../views/RoutineFlows.vue'),
      },
      {
        path: 'routines/create',
        name: RouteName.routineCreate,
        meta: {
          authorities: [Authorities.FlowFxCreate],
          isAllowed: hasAutomationsEnabled,
        },
        component: () => import('../views/FlowCreate.vue'),
      },
      {
        path: 'routines/:id',
        name: RouteName.routineDetails,
        component: () => import('../views/FlowDetails.vue'),
      },
      {
        path: 'archived',
        name: RouteName.archivedOverview,
        component: () => import('../views/ArchivedFlows.vue'),
      },
    ],
  },
  // TODO: Remove per 01-02-2024
  // Functions as a temp redirect for people with bookmarked Flows
  {
    path: 'flows/:id',
    name: 'legacy-flow-redirect',
    redirect: { name: RouteName.flowDetails },
  },
];
