<template>
  <router-link class="font-medium text-sm block leading-10 px-5 k-tabs-router-link" :to="routeOptions">
    <slot></slot>
  </router-link>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  props: {
    routeOptions: {
      type: Object as PropType<RouteLocationRaw>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.k-tabs-router-link {
  color: unset;
  box-shadow: 0 0.125rem 0 rgba($primary-color, 0);
  transition: box-shadow 0.3s ease-in-out;

  &.router-link-active,
  &:hover {
    color: $primary-color;
    box-shadow: 0 0.125rem 0 rgba($primary-color, 1);
  }
}
</style>
