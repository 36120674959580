import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { featuresApiService } from '@/modules/features/api/features/features-api.service';
import { FeaturesModel } from '@/modules/features/api/features/features.contracts';
import { store } from '@/store';

interface IFeaturesModuleState {
  isLoading: boolean;
  features: FeaturesModel;
}

@Module({ dynamic: true, store, name: 'features', namespaced: true })
class Features extends VuexModule implements IFeaturesModuleState {
  private _features: FeaturesModel = {};

  public isLoading = false;

  public get features(): FeaturesModel {
    return this._features;
  }

  @Action
  public async getFeatures(): Promise<FeaturesModel> {
    this.setLoadingState(true);

    const features: FeaturesModel = await featuresApiService.get();
    this.setFeaturesState(features);
    this.setLoadingState(false);

    return features;
  }

  @Mutation
  public setLoadingState(payload: boolean): void {
    this.isLoading = payload;
  }

  @Mutation
  public setFeaturesState(payload: FeaturesModel): void {
    this._features = { ...payload };
  }
}

export const FeaturesModule = getModule(Features);
