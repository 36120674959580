import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const hasInsightsEnabled = (): boolean => CustomerSettingsModule.settings.hasInsightsEnabled;

export const INSIGHTS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'insights',
    name: RouteName.insights,
    component: () => import('../views/InsightsView.vue'),
    meta: {
      authorities: [Authorities.InsightsView],
      isAllowed: hasInsightsEnabled,
    },
  },
];
