import { ColDef, ColumnState } from 'ag-grid-enterprise';

import * as CabinClassColumns from '@/models/columns/definitions';
import { ColumnId } from '@/models/enums/grid';
import { FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { PssName } from '@/modules/api/application/application-contracts';
import { ancillaryTotalRevenueRatioLegCabinColumn } from '@/modules/revenues/columns';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

import * as Columns from '../../definitions';

export function getInventoryTacticsSharedFlightCabinColumns(
  pss: PssName,
  performanceBandPickupDays: number[],
): ColDef<FlightViewLegCabinInventoryTactic>[] {
  const columns = [
    Columns.InventoryTacticFlightLineColumn,
    Columns.CabinCodeColumn,
    Columns.FlightCabinInventoryTacticsCapacity,
    Columns.FlightCabinInventoryTacticsLid,
    Columns.BookingsColumn,
    Columns.SeatAvailabilityColumn(pss === PssName.Radixx),
    Columns.InventoryTacticCabinLafFareValueColumn,
    Columns.LowestAvailableFareClassColumn,
    Columns.OwnFareColumn,
    Columns.CabinCompetitorFaresColumn,
    Columns.CabinCompetitorFaresDifferenceColumn,
    Columns.CabinRivalFaresColumn,
    Columns.CabinRivalFaresDifferenceColumn,
    Columns.ClusterLevelColumn,
    Columns.PerformanceScoreColumn,
    Columns.BidPriceColumn,
    ancillaryTotalRevenueRatioLegCabinColumn,
    ...CabinClassColumns.generateCabinPerformanceBandPickupCols(performanceBandPickupDays),
  ];
  if (CustomerSettingsModule.settings.hasNegoAllocationEnabled) {
    columns.push(Columns.InventoryTacticCabinNegotiatedAllocationsColumn);
  }
  return columns;
}

export function generateInventoryTacticsSharedFlightCabinColumnsState(performanceBandPickupDays: number[]): ColumnState[] {
  const columns = [
    { colId: ColumnId.InventoryTacticFlightLine, pinned: true, hide: false },
    { colId: ColumnId.CabinCode, pinned: true, hide: false },
    { colId: ColumnId.FlightCabinCapacity, hide: false },
    { colId: ColumnId.FlightCabinLid, hide: false },
    { colId: ColumnId.Bookings, hide: false },
    { colId: ColumnId.SeatAvailability, hide: false },
    { colId: ColumnId.CabinLafFareValue, hide: false },
    { colId: ColumnId.LowestAvailableFareClass, hide: false },
    { colId: ColumnId.FarePrice, hide: false },
    { colId: ColumnId.CabinCompetitorFares, hide: false },
    { colId: ColumnId.CabinCompetitorFaresDifference, hide: false },
    { colId: ColumnId.CabinRivalFares, hide: false },
    { colId: ColumnId.CabinRivalCompetitorFareDifference, hide: false },
    { colId: ColumnId.ClusterLevel, hide: false },
    { colId: ColumnId.PerformanceScore, hide: false },
    { colId: ColumnId.BidPrice, hide: false },
    ...CabinClassColumns.generateCabinPerformanceBandPickupCols(performanceBandPickupDays).flatMap((pickupDayColDef) => ({
      colId: pickupDayColDef.colId as string,
      hide: false,
    })),
  ];

  if (CustomerSettingsModule.settings.hasNegoAllocationEnabled) {
    columns.push({ colId: ColumnId.NegoAllocations, hide: false });
  }

  return columns;
}

export function getInventoryTacticsActiveOptimisationTacticsColumns(): ColDef<any>[] {
  const activeOptimisationTacticsChildren = [Columns.AutopilotColumn, Columns.OptimizationTacticColumn];
  activeOptimisationTacticsChildren.push(Columns.RecommendedLowestAvailableFareClassColumn);

  return activeOptimisationTacticsChildren;
}

export function generateInventoryTacticsActiveOptimisationTacticsColumnsState(): ColumnState[] {
  return [
    { colId: ColumnId.Autopilot, hide: false },
    { colId: ColumnId.OptimizationTactic, hide: false },
    { colId: ColumnId.RecommendedLowestAvailableFareClass, hide: false },
  ];
}

export function getInventoryTacticsShadowOptimisationTacticsColumns(): ColDef<any>[] {
  return [Columns.ShadowTacticsOptimizationTacticColumn, Columns.ShadowRecommendedLowestAvailableFareClassColumn];
}

export function generateInventoryTacticsShadowOptimisationTacticsColumnsState(): ColumnState[] {
  return [
    { colId: ColumnId.ShadowTacticsOptimizationTactic, hide: true },
    { colId: ColumnId.ShadowRecommendedLowestAvailableFareClass, hide: true },
  ];
}

export function generateInventoryTacticsPricingTacticsColumns(): ColDef<any>[] {
  return [Columns.PromotionColumn, Columns.PricingAdjustmentColumn, Columns.PricingAggregationColumn, Columns.PricingTacticColumn];
}

export function generateInventoryTacticsPricingTacticsColumnsState(): ColumnState[] {
  return [
    { colId: ColumnId.Promotion, hide: false },
    { colId: ColumnId.PricingAdjustment, hide: false },
    { colId: ColumnId.PricingAggregation, hide: false },
    { colId: ColumnId.PricingTactic, hide: false },
  ];
}
