import axios, { CancelToken } from 'axios';
import { ElMessage } from 'element-plus';

import { ApiClient, EddyBaseError, IntegrationClient } from '@/modules/api/base-client';
import { Page } from '@/modules/api/shared-contracts';
import { CalendarEventModel } from '@/modules/event-management/api/event-management-contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { MessageService } from '@/modules/shared';
import { ErrorCode } from '@/modules/shared/types/error-codes';
import { ITag } from '@/modules/tags';
import { i18n } from '@/plugins/i18n';
import { DateTimeService } from '@/services/date-time.service';
import { FilterService } from '@/services/filter.service';

import {
  FlightFilterModel,
  FlightLineModel,
  IOndsService,
  NegotiatedAllocations,
  SearchRequestParams,
  SourceFlight,
} from './flight-contracts';

const { t } = i18n.global;

export class OndsService implements IOndsService {
  public basePath = 'onds';

  public async getDeparted(filters: FlightFilterModel): Promise<FlightLineModel[]> {
    const flight = await ApiClient.get<FlightLineModel[]>(`${this.basePath}/departed`, filters);
    return this.setFlightLegsDepartureDates(flight);
  }

  private setFlightLegsDepartureDates(flight: FlightLineModel[]): FlightLineModel[] {
    if (flight) {
      flight.forEach((fl: FlightLineModel) => {
        if (!fl.legs) {
          return;
        }
        fl.legs = fl.legs.map((leg) => {
          leg.departureDate = DateTimeService.getDate({
            date: leg.departureDate.toString(),
          });
          return leg;
        });
      });
    }
    return flight;
  }

  // for saving tags
  public async patch(id: number, tags: ITag[]): Promise<FlightLineModel> {
    const createdTags = tags.filter((tag: ITag) => tag.id);

    const tagsList = createdTags.map((tag: ITag) => tag.id);

    const request = {
      tags: tagsList,
    };

    return await ApiClient.patch<FlightLineModel>(`/${this.basePath}/${id}`, request);
  }

  // control search
  public async search(
    transformedRequest: any,
    cancelToken: CancelToken,
    filters?: SearchRequestParams,
  ): Promise<{ page: Page; content: FlightLineModel[] }> {
    try {
      return await ApiClient.post<{
        page: Page;
        content: FlightLineModel[];
      }>(`${this.basePath}/search`, transformedRequest, filters, cancelToken);
    } catch (error) {
      if (axios.isCancel(error)) {
        ElMessage.info({
          message: t('search_cancelled'),
          duration: 2000,
        });
      }
      throw error;
    }
  }

  // control search pagination
  public async searchAll(
    request: FilterFieldDefinition[],
    cancelToken: CancelToken,
    params = {
      page: 0,
      size: 20000,
    },
  ): Promise<FlightLineModel[]> {
    const transformedRequest = FilterService.transformRequest(request);

    try {
      const search = await this.search(transformedRequest, cancelToken, { size: params.size.toString() });
      if (!search?.content) {
        throw new Error('RESPONSE_OVERLOAD');
      } else {
        return search.content;
      }
    } catch (error) {
      if ((error as EddyBaseError).response) {
        const err = error as EddyBaseError;
        if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.TooManyResults)) {
          MessageService.refineSearch();
        }
        if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.NoFlights)) {
          MessageService.noPlannedFlights();
        }
      } else {
        const err = error as Error;
        if (err?.message === 'RESPONSE_OVERLOAD') {
          MessageService.responseOverload();
        }
      }
      return [];
    }
  }

  // get single flight / source flight
  public async get(filters: FlightFilterModel): Promise<FlightLineModel[]> {
    const flight = await ApiClient.get<FlightLineModel[]>(`${this.basePath}`, filters);
    return this.setFlightLegsDepartureDates(flight);
  }

  public async getSourceFlight(filters: FlightFilterModel): Promise<SourceFlight[]> {
    return await ApiClient.get<SourceFlight[]>(`${this.basePath}/source-flights`, filters);
  }

  public async getById(id: number): Promise<FlightLineModel> {
    const flight = await ApiClient.get<FlightLineModel>(`${this.basePath}/${id}`);

    if (!flight.legs) {
      return;
    }
    flight.legs = flight.legs.map((leg) => {
      leg.departureDate = DateTimeService.getDate({
        date: leg.departureDate.toString(),
      });
      return leg;
    });

    return flight;
  }

  public async getNegotiatedAllocations(ondId: number): Promise<NegotiatedAllocations[]> {
    return await ApiClient.get<NegotiatedAllocations[]>(`${this.basePath}/${ondId}/negotiated-allocations`);
  }

  public async requestRealTimeBookingUpdate(carrierCode: string, flightNumber: string, departureDate: string): Promise<{ status: string }> {
    return await IntegrationClient.post<{ status: string }>('realtime/booked', {
      carrierCode,
      flightNumber,
      departureDate,
    });
  }

  public async updateLastViewed(ondId: number): Promise<string> {
    return await ApiClient.put<string>(`${this.basePath}/${ondId}/view`, '');
  }

  public async getCalendarEventsById(id: number): Promise<CalendarEventModel[]> {
    return await ApiClient.get<CalendarEventModel[]>(`${this.basePath}/${id}/calendar-events`);
  }
}

export const ondsService = new OndsService();
